import Container from "react-bootstrap/Container";
import './MainBanner.css';
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import home_main from "../assets/home_main.png"

export default function MainBanner() {
  const { t } = useTranslation();

  return (
    <>
      <Container className="MainBanner" fluid>
          <Image
            className="MainImage col-12 px-0"
            alt="Daalive"
            src={home_main}
            fluid
          />
        </Container>
      <Container>
          <div className="MainCaption col-12 align-self-center py-3 px-5 px-sm-2 text-center">
            <p className="MainText my-0">{t("main_banner_title")}</p>
          </div>
      </Container>
    </>
  );
}
